0*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    /* background-color : #d4d46d4e !important; */

}
body{
    font-family: "Inter", sans-serif;
}
